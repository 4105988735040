<template>
  <div class="state">
    <div class="header">
      <div class="left">
        <van-icon name="arrow-left" class="icon" @click="gopath('back')" />
      </div>
      <div class="title">支付</div>
    </div>
    <div class="content">
      <img v-if="state == 1" src="@/assets/images/success.png" class="img" />
      <img v-if="state == 0" src="@/assets/images/fail.png" class="img" />
      <div class="text">
        {{ state == 1 ? "支付成功" : state == 0 ? "支付失败" : "" }}
      </div>
      <div class="btn">
        <van-button v-if="state == 1" @click="gopath('/orderlist')" type="info"
          >查看订单</van-button
        >
        <van-button v-if="state == 0" @click="pay" type="info"
          >重新支付</van-button
        >
      </div>
    </div>
  </div>
</template>


<script>
import { orderstatus } from "@/api/order.js";
import { payOrder } from "@/api/user.js";
export default {
  components: {},
  data() {
    return {
      state: 2,
    };
  },
  created() {
    this.getorderstate();
    this.$toast.loading({
      message: "加载中...",
      forbidClick: true,
    });
  },
  methods: {
    getorderstate() {
      let formData = new FormData();
      formData.append("serviceId", this.$route.query.serviceId);
      formData.append("serviceType", this.$route.query.serviceType);
      let that = this;
      setTimeout(function () {
        orderstatus(formData).then((res) => {
          that.state = res.data.data;
        });
      }, 1500);
    },
    gopath(val, id) {
      if (val == "back") {
        window.sessionStorage.removeItem("payFlag");
        window.sessionStorage.removeItem("orderId");
        window.sessionStorage.removeItem('orderpayFlag');
        this.$router.go(-1);
      }
    },
    pay() {
      let formData = new FormData();
      formData.append("orderId", this.$route.query.serviceId);
      payOrder(formData).then((res) => {
        if (res.data.code == 0) {
          if (res.data.data.codeUrl) {
            window.location.href = res.data.data.codeUrl;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.state {
  .header {
    padding: 10px 10px;
    display: flex;
    .left {
      width: 10%;
      .icon {
        font-size: 24px;
        font-weight: 550;
      }
    }
    .title {
      width: 80%;
      text-align: center;
      font-size: 17px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #141414;
    }
  }
  .content {
    text-align: center;
    padding-top: 22vh;
    .img {
      width: 30%;
      margin: 0 auto;
    }
  }
  .btn {
    .van-button {
      margin-top: 30px;
      width: 192px;
      height: 40px;
      background: #5f7dff;
      border-radius: 5px;
    }
  }
}
</style>


